* {
    box-sizing: border-box !important;
    transition: all 0.5s ease-out !important;
    scroll-behavior: smooth;
    /* font-family: 'Poppins' !important; */
}

/* Netz Css 2022-03-24 */
@import url('https://use.typekit.net/dka7ttx.css');
body,
p {
    font-family: 'fort', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 32px;
    color: rgba(51, 51, 51, 0.8);
}

ul,
li {
    margin: 0px;
    padding: 0px;
    list-style: none;
}

img {
    max-width: 100%;
}

#root > div {
    float: left;
    width: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0px;
    padding: 0px;
    font-family: 'termina', sans-serif;
}

h1 {
    font-weight: 600;
    font-size: 66px;
    line-height: 88px;
    font-style: normal;
}

h2 {
    font-style: normal;
    font-weight: 600;
    font-size: 48px;
    line-height: 72px;
}

h3 {
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 42;
}

h4 {
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
}

h5 {
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    font-style: normal;
}

h6 {
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    font-style: normal;
}

.css-5m3gax-MuiInputBase-root-MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
    /* border: none; */
}

#root main {
    padding: 0px;
    width: 100%;
    background: #fff;
    margin: 110px 0px 0px;
}
#root .MuiContainer-root {
    max-width: 1330px;
    width: 100%;
    margin: 0px auto;
    position: relative;
    padding-left: 15px;
    padding-right: 15px;
}
#root button,
#root .btn,
.default-btn {
    border-radius: 100px;
    line-height: 55px;
    padding: 0px 33px;
    text-decoration: none;
    font-weight: 700;
    font-size: 20px;
    box-shadow: none;
}

.css-qfxz4f-MuiButtonBase-root-MuiButton-root,
.default-btn.blue {
    background: #2980ff;
    color: #fff;
}

.css-qfxz4f-MuiButtonBase-root-MuiButton-root:hover,
.default-btn.blue:hover {
    background: #333333;
    color: #fff;
}
.css-1ln8qjd-MuiButtonBase-root-MuiButton-root,
.default-btn.grey {
    background: #333333;
    color: #fff;
}

.css-1ln8qjd-MuiButtonBase-root-MuiButton-root:hover,
.default-btn.grey:hover {
    background: #2980ff;
    color: #fff;
}
#wisdom .MuiContainer-root button,
#wisdom .MuiContainer-root .btn {
    background: #fff;
    color: #333333;
    min-width: 275px;
    font-size: 24px;
    line-height: 61px;
}
#wisdom .MuiContainer-root button,
.default-btn.white {
    background: #fff;
    color: #333333;
}
#wisdom .MuiContainer-root button:hover,
.default-btn.white:hover,
#wisdom .MuiContainer-root .btn.btn-white:hover {
    background: #2980ff;
    color: #fff;
}

.app {
    float: left;
    width: 100%;
}

.flex-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

#root header {
    display: flex !important;
    justify-content: space-between;
    height: 111px;
    width: 100% !important;
    position: fixed;
    z-index: 100;
    max-width: 100%;
    align-items: center;
    transition: all 1s ease-in-out !important;
    padding-top: 28px;
    background-color: #dfefff !important;
    padding-bottom: 32px;
    border: 0px !important;
}

header.fix {
    background-color: #fff;
    box-shadow: 0px 0px 5px rgb(0 0 0 / 20%);
}
header .menubar {
    padding: 0px;
    display: flex;
    justify-content: space-between;
}

.logo {
    margin-top: 0px;
    max-width: 201px;
    width: auto;
}

.app > div {
    float: left;
    width: 100%;
}

.nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 608px;
    width: 100%;
    background: none;
}

.nav a {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    color: #333333;
    text-decoration: none;
}
.nav a:hover {
    color: #2980ff;
}

.launch-btn {
    font-size: 14px;
    line-height: 19px;
    min-width: 149px;
    padding: 14px 33px;
    border-radius: 100px;
    text-decoration: none;
    font-weight: 700;
    box-shadow: none;
    background: #333333;
    color: #fff;
}
.launch-btn:hover {
    background: #2980ff;
    color: #fff;
}

#banner .btn {
    /* float: left;
    padding-top: 0;
    padding-bottom: 0; */
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    justify-content: space-evenly !important;
}

#root .btn.btn-grey {
    background: #333333;
}

#banner .btn img {
    display: inline-block;
    margin-left: 10px;
    margin-top: 0px;
    /* float: right; */
}

#banner .left-block {
    width: 60%;
    padding-top: 14px;
}
#banner .left-block h1 {
    color: #3d4a57;
}
#banner .left-block p {
    color: rgba(51, 51, 51, 0.8);
    margin-bottom: 42px;
    font-weight: 400;
    font-size: 20px;
    line-height: 32px;
}

#banner .left-block ul {
    display: flex;
    width: auto;
}

#banner .left-block ul li {
    float: left;
    margin-right: 15px;
    width: auto;
    padding: 0px;
}

#banner .right-block {
    width: 36%;
    text-align: right;
}

#banner {
    float: left;
    width: 100%;
    padding: 63px 0px 156px;
    position: relative;
    background-color: #dfefff;
}

#banner:before {
    content: '';
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0px;
    top: 0px;
    background: url(assets/images/landing/banner-background.png) no-repeat right bottom;
    opacity: 0.3;
}

.lightblue {
    background-color: #dfefff;
}

/* About Style */

#about {
    padding: 138px 0px 113px;
    background: #fff;
    display: inline-block;
    width: 100%;
}
#about h2 {
    color: #333;
}
#about p {
    max-width: 790px;
    font-size: 24px;
    line-height: 36px;
    margin-top: 4px;
    margin-bottom: 10px;
}

#about > .MuiContainer-root > div.MuiGrid-root {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

#about > .MuiContainer-root > div.MuiGrid-root > div {
    width: 100%;
    max-width: 406px;
    border-top: 1px solid #000;
    padding: 54px 0px;
    margin-top: 30px;
}
#about > .MuiContainer-root > div.MuiGrid-root > div h4 {
    color: rgba(51, 51, 51, 0.9);
    line-height: 29px;
}
#about > .MuiContainer-root > div.MuiGrid-root > div p {
    font-size: 20px;
    line-height: 32px;
    margin-top: 33px;
}

#about > .MuiContainer-root > div.MuiGrid-root > div > .MuiBox-root {
    min-height: 58px;
    margin-bottom: 15px;
}

/* Analysis */

#analysis {
    padding: 75px 0px 110px;
    position: relative;
    background-color: #dfefff;
    overflow: hidden;
}
#analysis .MuiGrid-grid-xs-6 > p {
    max-width: 487px;
    margin: 0px;
    font-weight: 400;
    font-size: 24px;
    line-height: 36px;
}
#root #analysis .MuiContainer-root {
    position: inherit;
}
#analysis .right-block {
    position: absolute;
    right: 0px;
    bottom: 30px;
    width: 50%;
    max-width: 800px;
}

#analysis h2 {
    font-weight: 400;
    color: #3d4a57;
}

#analysis .left-block p {
    font-size: 24px;
    line-height: 36px;
    margin-top: 10px;
    max-width: 567px;
}

#analysis .flex-container {
    align-items: flex-start;
}

#analysis ul {
    /* flex-direction: column; */
    margin-top: 32px;
    margin-left: 13px;
}

#analysis ul li {
    margin: 24px 0px 26px 0px;
    padding: 0px;
}

#analysis ul li a {
    text-decoration: none;
    transition: 0.3s width ease-in-out;
    max-width: 426px;
    background: rgba(255, 255, 255, 0.8);
    border-radius: 12px;
    padding: 28px 28px 28px 32px;
    display: inline-block;
    width: 100%;
}

#analysis ul li a h4 {
    font-size: 28px;
    line-height: 36px;
    margin-bottom: 10px;
    color: #333;
    margin-top: 10px;
}

#analysis ul li a p {
    margin: 0px;
    max-width: 324px;
    font-size: 18px;
    line-height: 22px;
}
#analysis ul li a:hover,
#analysis ul li.active a {
    max-width: 448px;
    box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.16);
}
#analysis ul li.active a h4,
#analysis ul li a:hover h4 {
    color: #177fff;
}
.carousel .slide img {
    max-width: 800px;
    float: right;
}
#analysis ul li.slide {
    margin: 0px;
}
#analysis .slick-slider .slick-list .slick-track > div {
    position: relative;
}
#analysis .slick-slider .slick-list .slick-track > div .MuiButton-root,
#analysis ul li.slide div a {
    color: #fff;
    border-radius: 100px;
    line-height: 55px;
    padding: 0px 33px 0px 6px;
    text-decoration: none;
    font-weight: 700;
    font-size: 20px;
    width: 170px;
    position: absolute;
    left: 30px;
    bottom: 60px;
    background: #333333 url(assets/images/landing/Vector.png) no-repeat 77% 49%;
}
#analysis ul li.slide div a:hover {
    background: #2980ff url(assets/images/landing/Vector.png) no-repeat 77% 49%;
    color: #fff;
}
#analysis .right-block .mobile-image {
    display: none;
}

/* Slider Css */
#analysis .slick-slider .slick-slide img {
    display: block;
    width: 100%;
}

/* chat */

#chat {
    padding: 132px 0px 136px;
}

#chat .left-block {
    width: 50%;
    text-align: center;
}

#chat .right-block {
    width: 50%;
}

#chat .right-block h2 {
    font-size: 70px;
    max-width: 400px;
    line-height: 80px;
    margin-bottom: 30px;
    font-weight: 800;
    color: #333333;
}

#chat .right-block ul {
    margin: 0px 0px 40px;
}

#chat .right-block ul li {
    position: relative;
    padding-left: 33px;
    margin-bottom: 20px;
    max-width: 499px;
}

#chat .right-block ul li:before {
    content: '';
    background: url(assets/images/landing/tick.png) no-repeat 0px 0px;
    height: 23px;
    width: 23px;
    position: absolute;
    left: 0px;
    top: 6px;
}

/* Community */

#community {
    padding: 108px 0px 254px;
    background: #dfefff url(assets/images/landing/enthusiasm.png) no-repeat right bottom;
}

#analysis .flex-container {
    align-items: flex-start;
}

#community h2 {
    font-weight: 700;
    color: #2888ff;
    margin-bottom: 4px;
    display: inline-block;
    line-height: 62.4px;
}

#community h2 span {
    color: #3d4a57;
}

#community .left-block {
    max-width: 620px;
    padding-left: 27px;
}

#community .left-block p {
    font-weight: 400;
    font-size: 24px;
    line-height: 36px;
    margin-bottom: 64px;
    max-width: 540px;
}
#community .mobile-block {
    display: none;
}

/* updates */

#updates {
    padding: 114px 0px 85px;
    background-color: #3d4a57;
}

#updates .flex-container {
    align-items: flex-start;
}
#updates > .flex-container > div {
    justify-content: space-between;
}
#updates .left-block {
    width: 50%;
    margin-left: 10px;
}

#updates ul {
    display: inline-block;
    width: 100%;
    -webkit-column-count: 3;
    -moz-column-count: 3;
    column-count: 3;
}

#updates ul li {
    margin: 0px 18px 16px 0px;
    width: 202px;
    float: left;
    background: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 6px;
    padding: 20px 14px;
    -webkit-transition: 1s ease all;
    display: inline-block;
}
#updates ul li h5 {
    color: #000;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
}
#updates ul li p {
    margin: 20px 0px 0;
    font-weight: 400;
    font-size: 16px;
    line-height: 12px;
    color: #333;
    font-family: 'Termina';
}

#updates ul li p span {
    display: inline-block;
    width: 100%;
    color: #219653;
    margin-top: 15px;
}

#updates ul li:nth-child(3) {
    margin-top: 45px;
}

#updates ul li:nth-child(6),
#updates ul li:nth-child(5) {
    margin-right: 0px;
}

#updates .right-block {
    width: 50%;
    max-width: 526px;
    background: rgba(255, 255, 255, 0.05);
    border-radius: 12px;
    padding: 35px 32px 32px;
    color: #fff;
    margin-top: 31px;
}

#updates .right-block h2 {
    font-weight: 600;
    font-size: 32px;
    line-height: 42px;
}

#updates .right-block p {
    color: rgba(255, 255, 255, 0.8);
    font-size: 20px;
    line-height: 36px;
    margin-top: 10px;
}

#updates form {
    width: 100%;
    float: left;
    max-width: 463px;
    margin-top: 26px;
    position: relative;
}

#updates form label {
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    margin-left: 26px;
}

#updates form .form-row {
    padding: 4px;
    background: #ffffff;
    border-radius: 36px;
    margin: 2px 0px 96px 0px;
}
#updates form div.MuiBox-root .MuiFormControl-root label {
    display: none;
}
#updates form div.MuiBox-root .MuiFormControl-root,
#updates form div.MuiBox-root .MuiFormControl-root .MuiOutlinedInput-root {
    display: inline-block;
    width: 100%;
    border-radius: 50px;
    border: 0px;
}
#updates form div.MuiBox-root .MuiFormControl-root .MuiOutlinedInput-root input[type='text'] {
    width: calc(100% - 152px);
    border: 0px;
    border-radius: 110px;
    padding: 0px 15px 0px 25px;
    outline: none;
    font-size: 18px;
    line-height: 55px;
    font-weight: 400;
    color: rgba(51, 51, 51, 0.4);
    height: auto;
}
#updates form .form-row input::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: rgba(51, 51, 51, 0.4);
}
#updates form .form-row input::-moz-placeholder {
    /* Firefox 19+ */
    color: rgba(51, 51, 51, 0.4);
}
#updates form .form-row input:-ms-input-placeholder {
    /* IE 10+ */
    color: rgba(51, 51, 51, 0.4);
}
#updates form .form-row input:-moz-placeholder {
    /* Firefox 18- */
    color: rgba(51, 51, 51, 0.4);
}

#updates form button {
    font-weight: 700;
    font-size: 14px;
    color: #ffffff !important;
    width: 152px;
    line-height: 47px !important;
    border: 0px;
    height: auto;
    padding: 0px;
    letter-spacing: 0px;
    cursor: pointer;
    position: absolute;
    bottom: 4px;
    right: 4px;
}
#updates form div.MuiBox-root .MuiFormControl-root .MuiOutlinedInput-root fieldset {
    border: 0px;
}
/* Team */
#team {
    background: #fff;
    text-align: center;
    padding-top: 86px;
    padding-bottom: 90px;
}

#team h2 {
    font-weight: 600;
    font-size: 40px;
    line-height: 48px;
    color: #3d4a57;
}

#team p {
    color: rgba(51, 51, 51, 0.6);
    margin-top: 16px;
    margin-bottom: 30px;
    font-size: 22px;
    line-height: 30.8px;
}

#team .teamlist {
    display: inline-block;
    width: 100%;
    margin-top: 87px;
    display: flex;
    flex-direction: row;
    /* align-items: center; */
    justify-content: center;
}

#team .teamlist > div {
    /* float: left; */
    width: 25%;
    text-align: center;
    margin-bottom: 40px;
}

#team .teamlist > div span.tw-icon {
    display: inline-block;
    width: 100%;
    margin-top: 5px;
}
#team .teamlist > div span.tw-icon img {
    display: inline-block;
    margin: 0px 3px;
}

#team .teamlist > div {
    text-decoration: none;
}
#team .teamlist > div > span {
    display: inline-block;
    width: 100%;
}

#team .teamlist > div h4 {
    font-weight: 900;
    font-size: 24px;
    line-height: 29px;
    color: #072125;
    margin-top: 10px;
    margin-bottom: 10px;
}

#team .teamlist > div span,
#team .teamlist > div p {
    font-weight: 400;
    font-size: 18px;
    color: #20484f;
    line-height: 27px;
}

#wisdom {
    background: #484848 url(assets/images/landing/ftr-bg.png) no-repeat left top;
    text-align: center;
    color: #fff;
    padding: 72px 0px 54px 0px;
    background-size: cover;
}

#wisdom h2 {
    font-weight: 900;
    font-size: 36px;
    line-height: 43px;
}

#wisdom p {
    max-width: 933px;
    font-weight: 400;
    font-size: 22px;
    line-height: 36px;
    color: rgba(255, 255, 255, 0.6);
    margin: 27px auto 84px;
}

#wisdom .default-btn {
    font-weight: 700;
    font-size: 24px;
    line-height: 33px;
}

/* Footer */

footer {
    background: #3d4a57;
    float: left;
    width: 100%;
    padding: 65px 0px 80px;
}

.ftr-left {
    max-width: 50%;
    width: 50%;
    margin: 0px;
    padding-left: 50px;
}

.ftr-left img {
    float: left;
    margin-bottom: 20px;
}
footer .flex-container {
    justify-content: flex-start;
}
.ftr-left p {
    font-size: 14px;
    line-height: 21px;
    color: #ffffff;
    max-width: 329px;
    float: left;
    clear: left;
    margin-bottom: 0;
}

.ftr-right {
    max-width: 50%;
    width: 50%;
    display: flex;
    padding-left: 212px;
    margin: 0px;
}

.ftr-right .company {
    max-width: 200px;
}

.ftr-right h6 {
    color: #fff;
    margin-bottom: 20px;
    font-weight: 900;
    line-height: 22px;
}

.ftr-right ul li {
    display: inline-block;
    width: 100%;
    margin-bottom: 20px;
    padding: 0px;
}

.ftr-right ul li a {
    font-size: 16px;
    color: #fff;
    text-decoration: none;
}

.ftr-right ul li a:hover {
    opacity: 0.3;
}

#copywrite {
    display: inline-block;
    width: 100%;
    margin-top: 10px;
}

#copywrite p {
    font-size: 14px;
    color: #fff;
    padding-left: 50px;
}
.navItem.title {
    display: none;
}

/* Table css */
#root .appheader header {
    padding: 23px 0px 16px;
    background: url(assets/images/landing/header-bg.png) no-repeat left top;
    height: auto;
    background-size: cover;
    position: relative !important;
}
.light.appdash,
#root .appheader header:before {
    content: '';
    background: #dfefff;
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    opacity: 0.8;
}
.appheader header .MuiToolbar-gutters {
    padding: 0px;
    padding: 0px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.appheader header > div > div {
    display: inline-block;
    width: 100%;
}
.appheader header > div > div .MuiTypography-body1 {
    float: left;
    max-width: 250px;
}
.appheader header > div > div .app-header-menu {
    float: left;
    margin-left: 40px;
    margin-top: 15px;
}
.appheader header > div > div .app-header-menu a:hover {
    background: none;
}

.header-list-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}

.header-td {
    border: none;
}

.topbar {
    display: block;
    width: 100%;
}
.topbar ul {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    background: #30455a;
    margin: 0px;
    padding: 0px 60px;
}
.topbar ul li {
    width: max-content;
    float: left;
    font-size: 14px;
    line-height: 32px;
    font-weight: 700;
    color: #fff;
    padding: 6px 0px;
    margin: 0px;
}
.topbar ul li span {
    font-weight: normal;
    margin-left: 2px;
    display: inline-block;
}
#root .datatab table tbody tr th a {
    color: #000;
    text-decoration: none;
}
.light .appheader header > div > div .app-header-menu a {
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    color: #333333;
}
.appheader header > div > div .app-header-menu a.active {
    font-weight: 700;
    color: #177fff;
}
.appheader header > div > div > a {
    float: right;
}
.MuiBox-root > .css-1tndf5z-MuiInputBase-root-MuiInput-root:before {
    display: none;
}

.MuiBox-root > .css-1tndf5z-MuiInputBase-root-MuiInput-root:after {
    background: url(assets/images/icons/search-grid.svg) no-repeat 94% 12px;
    content: '';
    border: 0px;
    width: 20px;
    height: 38px;
    position: absolute;
    right: 18px;
    top: 6px;
    transition: none;
    z-index: 9;
    transform: none;
    left: inherit;
    bottom: inherit;
}
#root .appheader .MuiContainer-root {
    max-width: 100%;
    padding-left: 70px;
    padding-right: 70px;
}
main .MuiTableContainer-root {
    margin-top: 108px;
}
.datatab table thead tr th {
    background-color: #333333;
    color: #fff !important;
    font-size: 14px;
    font-weight: 700;
    padding: 11px 10px;
}
.datatab table tbody tr th,
.datatab table tbody tr td {
    /* background: rgba(0, 0, 0, 0.02); */
    border: 0px !important;
}
#root .datatab table tbody tr td.tvl-chart {
    display: flex;
    flex-direction: inherit;
    align-items: center;
}
#root .datatab table tbody tr td.tvl-chart > .tvl-block {
    margin-right: 10px;
}
/* .datatab table tbody tr:hover th,
.datatab table tbody tr:hover td {
    background: rgba(0, 0, 0, 0.05);
} */

.MuiBox-root.css-8atqhb > .MuiPaper-root > .MuiBox-root.css-0 {
    display: flex;
    width: 100%;
    padding: 0px 0px 0px 60px;
    background: #3d4a57;
    justify-content: space-between;
}
.toplist {
    display: inline-block;
    width: 100%;
    padding: 9px 0px 9px 0px;
    margin: 0px;
}
.toplist li {
    display: inline-block;
    float: left;
    width: auto;
    border-radius: 4px;
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    color: rgba(255, 255, 255, 0.8);
    margin-right: 30px;
    padding: 6px 16px;
}
.toplist li:hover,
.toplist li.active {
    background: rgba(129, 161, 193, 0.4);
    color: #fff;
}
.toplist li:hover {
    cursor: pointer;
}
.MuiBox-root.css-8atqhb > .MuiPaper-root > .MuiBox-root.css-0 input[type='text'] {
    font-weight: 400;
    font-size: 17px;
    line-height: 23px;
    color: rgba(255, 255, 255, 0.8);
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.03), rgba(255, 255, 255, 0.03)), #233343;
    padding: 12px 50px 13px 19px;
    height: auto;
    display: inline-block;
    width: 375px;
}
.MuiBox-root.css-8atqhb > .MuiPaper-root > .MuiBox-root.css-0 input::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: rgba(255, 255, 255, 0.8);
}
.MuiBox-root.css-8atqhb > .MuiPaper-root > .MuiBox-root.css-0 input::-moz-placeholder {
    /* Firefox 19+ */
    color: rgba(255, 255, 255, 0.8);
}
.MuiBox-root.css-8atqhb > .MuiPaper-root > .MuiBox-root.css-0 input:-ms-input-placeholder {
    /* IE 10+ */
    color: rgba(255, 255, 255, 0.8);
}
.MuiBox-root.css-8atqhb > .MuiPaper-root > .MuiBox-root.css-0 input:-moz-placeholder {
    /* Firefox 18- */
    color: rgba(255, 255, 255, 0.8);
}

.sort-arrow-flex {
    /* border: 1px solid black; */
    display: flex !important;
    flex-direction: column !important;
}

.datatab table thead tr th > span {
    display: flex !important;
    text-align: left !important;
    flex-direction: row !important;
    align-items: flex-start !important;
    position: relative;
    /* justify-content: space-between; */
}
.datatab table thead tr th span .secondary-column-name {
    color: rgba(255, 255, 255, 0.7);
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
}
.datatab table thead tr th span .main-column-name {
    font-weight: 700;
    font-size: 14px;
    line-height: 19px;
    color: #fff;
}
#root .datatab table tbody tr td {
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #000000;
    text-align: left;
    padding: 15px 10px;
}
#root .datatab table tbody tr th {
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: #000000;
    display: flex;
    flex-direction: row !important;
    width: auto;
    max-width: fit-content;
}

.nft-count {
    margin-left: 10px;
}

.collection-data {
    display: flex;
    flex-direction: column !important;
    justify-content: space-evenly;
}

#root .datatab table tbody tr td .tvl-chart {
    width: 150px;
    max-width: 150px;
}
.datatab table thead tr th span svg {
    position: relative;
}
.datatab table thead tr th:first-child span svg,
.datatab table thead tr th:last-child span svg {
    top: 0;
}
.datatab table thead tr th:first-child,
.datatab table tbody tr th:first-child,
.datatab table tbody tr td:first-child {
    padding-left: 70px !important;
}
.MuiTableContainer-root.datatab {
    margin: 0px;
}
#root .datatab table tbody tr td .reward-value {
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: #3e93ff;
    font-family: 'termina', sans-serif;
}

#root .datatab table tbody tr td .price-value {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #333333;
}
#root .datatab table tbody tr td .blockchain-value {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: rgba(0, 0, 0, 0.6);
}
#root .datatab table tbody tr td .positive,
#root .light .datatab table tbody tr td .change7d_percent-value,
#root .light .datatab table tbody tr td .change7d-value {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #27ae60;
}
#root .datatab table tbody tr td .negative,
#root .light .datatab table tbody tr td .negative {
    color: #eb5757;
    font-size: 12px;
    line-height: 16px;
}
#root .datatab table tbody tr td:nth-child(3) {
    text-transform: capitalize;
}

#root .datatab table tbody tr td .reward-value.ohm {
    font-family: 'fort', sans-serif;
}
.app-copyright.MuiBox-root.css-0 {
    display: inline-block;
    width: 100%;
    background: #313c46;
}
.app-copyright .MuiContainer-root {
    max-width: 100% !important;
    padding: 10px 60px !important;
}
.app-copyright .MuiContainer-root p {
    color: #fff;
    display: inline-block;
    margin: 0px 30px 0px 0px;
    font-size: 14px;
}

#root .datatab table tbody tr td:last-child {
    font-size: 14px;
    line-height: 19px;
}

.footer-menu-links {
    color: #ffffff;
}

.footer-social-media > a,
.footer-menu-links > a,
.topbar a {
    transition: 0.6s ease;
}

.footer-social-media > a:hover,
.footer-menu-links > a:hover,
.topbar a:hover {
    opacity: 0.5;
}

/* Dark theme */
#root .dark header:before {
    background: #000000;
    opacity: 0.6;
}
#root .dark .appheader header > div > div .MuiTypography-body1 {
    position: relative;
}

#root .dark span.apexcharts-legend-text {
    color: white;
}

.appheader header > div > div .app-header-menu a {
    color: rgba(255, 255, 255, 0.6);
}
.dark .appheader header > div > div .app-header-menu a {
    color: rgba(255, 255, 255, 0.6);
    font-size: 18px;
    line-height: 24px;
    font-weight: 400;
}

.dark .appheader header > div > div .app-header-menu a.active {
    font-weight: 700;
    color: #fff;
}

.dark .appheader header > div > div > a.launch-btn {
    background: #fff;
    color: #333333;
}
.dark .appheader header > div > div > a.launch-btn:hover {
    background: #2980ff;
    color: #fff;
}
#root .dark main,
#root .light main {
    margin: 0px 0px 0px;
}

#root .dark main {
    background-color: #30455a;
}

.dark {
    background-color: #30455a;
}

.search-asset-input > div:before {
    display: none;
}
.dark .MuiTable-root {
    background: #30455a;
}

/* .dark .datatab table tbody tr th,
.dark .datatab table tbody tr td {
    background: rgba(250, 250, 250, 0.02);
}
.dark .datatab table tbody tr:hover th,
.dark .datatab table tbody tr:hover td {
    background: rgba(250, 250, 250, 0.05);
} */

.btn-link:hover {
    background: none;
}

#root .dark .datatab table tbody tr td .reward-value.ohm,
#root .dark .datatab table tbody tr td .price-value,
#root .dark .datatab table tbody tr td,
#root .dark .datatab table tbody tr th {
    color: #fff;
}

#root .dark .datatab table tbody tr td .reward-value {
    color: #b7d9ff;
}

#root .dark .datatab table tbody tr td .blockchain-value {
    color: rgba(255, 255, 255, 0.6);
}

#root .appdash main > div > div {
    margin-bottom: 0px;
    border-radius: 0px;
}

.loader-container,
.records-not-found {
    display: flex;
    align-items: center;
    justify-content: center !important;
}

.loader-container > span {
    margin: 5px;
}

.MuiTableContainer-root.datatab {
    overflow-y: hidden;
}

.records-not-found {
    font-size: 32px;
    margin-top: 32px;
}
/* Pagination */

.MuiPagination-ul {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 15px 15px;
}
#root .MuiPagination-ul li {
    margin: 0px 10px;
}
#root .MuiPagination-ul li button {
    display: inline-block;
    float: left;
    width: auto;
    border-radius: 4px;
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    color: rgba(255, 255, 255, 0.8);
    margin-right: 0px;
    padding: 6px 16px;
}
#root .MuiPagination-ul li.active button {
    background: rgba(129, 161, 193, 0.4);
    color: #fff;
}
.pagination-container {
    border-radius: 0px;
}
.pagination-container nav {
    border-radius: 0px;
}

#root .datatab table tbody tr th a,
#root .datatab table tbody tr td a {
    color: #3e93ff;
    text-decoration: none;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    font-family: 'termina', sans-serif;
}

#root .light main .MuiPagination-ul {
    border-top: 1px solid #ddd;
}
#root .dark main .MuiPagination-ul {
    border-top: 1px solid #ffffff30;
    background: #34495d;
}
#root .light main .MuiPagination-ul li button {
    color: #000000;
}
#root .light main .MuiPagination-ul li button:hover,
#root .light main .MuiPagination-ul li button.Mui-selected {
    background-color: #3e93ff;
    color: #fff;
}

.light .MuiBox-root .asset-details-container {
    background-color: #fff !important;
    justify-content: flex-start !important;
    padding-bottom: 20px !important;
}
.light main > .MuiBox-root > .MuiPaper-root > .MuiBox-root:first-child .left-top {
    background: #313e4a;
    border-radius: 2px;
    margin: 9px 0px;
    width: auto;
    height: 28px;
    padding: 3px 8px 0px;
    font-family: 'Termina';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    color: rgba(255, 255, 255, 0.8);
    /* to center align the input text */
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.light .MuiBox-root .asset-details-container > .asset-details {
    margin: 13px;
    background: #f5f5f599;
    width: calc(33.33% - 13px);
    padding: 23px;
}
.light .MuiBox-root .asset-details-container > .asset-details h2 {
    font-weight: 700;
    font-size: 17px;
    line-height: 20px;
    color: #333333;
}
.light .MuiBox-root .asset-details-container > .asset-details ul {
    display: inline-block;
    width: 100%;
}
.light .MuiBox-root .asset-details-container > .asset-details ul li {
    display: flex;
    width: 100%;
    border-bottom: 1px solid rgba(51, 51, 51, 0.1);
    align-items: center;
    justify-content: space-between;
    padding: 0px;
}
.light .MuiBox-root .asset-details-container > .asset-details ul li span.label {
    font-weight: 400;
    font-size: 14px;
    line-height: 48px;
    color: #333333;
    font-family: 'fort', sans-serif;
}
.light .MuiBox-root .asset-details-container > .asset-details ul li span.value {
    font-weight: 700;
    font-size: 14px;
    line-height: 48px;
    color: #333333;
}
.light .MuiBox-root .asset-details-container > .asset-details ul li span.value a {
    color: #3e93ff;
    text-decoration: none;
}
.light .MuiBox-root .asset-details-container > .pie-chart-container {
    margin: 13px 13px 13px 0px;
    background: #f5f5f599;
    width: calc(66.33% - 13px);
    padding: 23px;
    /* original */
    /* max-width: 713px; */
    /* new */
    max-width: 740px;
}
.light .MuiBox-root .asset-details-container > .pie-chart-container > div:first-child {
    font-weight: 700;
    font-size: 17px;
    line-height: 20px;
    color: #333333;
    font-family: 'termina', sans-serif;
    margin-bottom: 50px;
}
.light .MuiBox-root .asset-details-container > .pie-chart-container > div:first-child .subtitle {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: rgba(51, 51, 51, 0.5);
}
#chart {
    display: inline-block;
}

#chart .apexcharts-yaxis .apexcharts-yaxis-texts-g text {
    font-size: 12px;
    line-height: 16px;
    color: #333333;
    fill: #333333;
}
#root .light .platform-tvl-info {
    background: #82a1c1;
    padding: 21px 60px 16px;
    justify-content: flex-start;
}
#root .light .platform-tvl-info div.first {
    background: rgba(51, 51, 51, 0.8);
    color: #fff;
    border-radius: 0px;
}
#root .light .platform-tvl-info div.first > div {
    padding: 16px;
}
#root .light .platform-tvl-info div.first > div p {
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #ffffff;
}
#root .light .platform-tvl-info div.first > div > div {
    font-weight: 700;
    font-size: 32px;
    line-height: 43px;
    color: #ffffff;
    padding: 0px;
}

#root .light .platform-tvl-info div.last {
    background: #333333;
    color: #fff;
    border-radius: 0px;
    margin-left: 15px;
}
#root .light .platform-tvl-info div.last > div {
    padding: 16px;
}
#root .light .platform-tvl-info div.last > div p {
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #ffffff;
}
#root .light .platform-tvl-info div.last > div > div {
    font-weight: 700;
    font-size: 32px;
    line-height: 43px;
    color: #ffffff;
    padding: 0px;
}

.breadcrumb-link {
    color: #9bb5cd;
    text-decoration: none;
}

/* Dark style */
.dark .MuiBox-root .asset-details-container {
    background-color: #30455a !important;
    justify-content: flex-start !important;
    padding-bottom: 80px !important;
}

.dark main > .MuiBox-root > .MuiPaper-root > .MuiBox-root:first-child .left-top {
    background: #313e4a;
    border-radius: 2px;
    margin: 9px 0px;
    width: auto;
    padding: 8px;
    font-family: 'Termina';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    color: rgba(255, 255, 255, 0.8);
}

.dark .MuiBox-root .asset-details-container > .asset-details {
    margin: 13px;
    background: #314152;
    width: calc(33.33% - 13px);
    padding: 23px;
}
.dark .MuiBox-root .asset-details-container > .asset-details h2 {
    font-weight: 700;
    font-size: 17px;
    line-height: 20px;
    color: #fff;
}
.dark .MuiBox-root .asset-details-container > .asset-details ul {
    display: inline-block;
    width: 100%;
}
.dark .MuiBox-root .asset-details-container > .asset-details ul li {
    display: flex;
    width: 100%;
    border-bottom: 1px solid rgba(51, 51, 51, 0.1);
    align-items: center;
    justify-content: space-between;
    padding: 0px;
}
.dark .MuiBox-root .asset-details-container > .asset-details ul li span.label {
    font-weight: 400;
    font-size: 14px;
    line-height: 48px;
    color: #fff;
    font-family: 'fort', sans-serif;
}
.dark .MuiBox-root .asset-details-container > .asset-details ul li span.value {
    font-weight: 700;
    font-size: 14px;
    line-height: 48px;
    color: #fff;
}
.dark .MuiBox-root .asset-details-container > .asset-details ul li span.value a {
    color: #3e93ff;
    text-decoration: none;
}
.dark .MuiBox-root .asset-details-container > .pie-chart-container {
    margin: 13px 13px 13px 0px;
    background: #314152;
    width: calc(66.33% - 13px);
    padding: 23px;
    /* original */
    /* max-width: 713px; */
    /* new */
    max-width: 740px;
}
.dark .MuiBox-root .asset-details-container > .pie-chart-container > div:first-child {
    font-weight: 700;
    font-size: 17px;
    line-height: 20px;
    color: #fff;
    font-family: 'termina', sans-serif;
    margin-bottom: 50px;
}
.pie-chart-container .MuiBox-root {
    display: inline-block;
    width: 100%;
}
#chart {
    width: 100%;
    height: 380px;
}
.pie-chart-container .MuiBox-root #chart {
    height: 270px;
}

#chart .apexcharts-yaxis .apexcharts-yaxis-texts-g text .tspan,
#chart .apexcharts-yaxis .apexcharts-yaxis-texts-g text .title {
    font-size: 12px;
    line-height: 16px;
    color: #fff;
}

#root .dark .platform-tvl-info {
    background: #82a1c1;
    padding: 21px 60px 16px;
    justify-content: flex-start;
}
#root .dark .platform-tvl-info div.first {
    background: rgba(51, 51, 51, 0.8);
    color: #fff;
    border-radius: 0px;
}
#root .dark .platform-tvl-info div.first > div {
    padding: 16px;
}
#root .dark .platform-tvl-info div.first > div p {
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #ffffff;
}
#root .dark .platform-tvl-info div.first > div > div {
    font-weight: 700;
    font-size: 32px;
    line-height: 43px;
    color: #ffffff;
    padding: 0px;
}

#root .dark .platform-tvl-info div.last {
    background: #333333;
    color: #fff;
    border-radius: 0px;
    margin-left: 15px;
}
#root .dark .platform-tvl-info div.last > div {
    padding: 16px;
}
#root .dark .platform-tvl-info div.last > div p {
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #ffffff;
}
#root .dark .platform-tvl-info div.last > div > div {
    font-weight: 700;
    font-size: 32px;
    line-height: 43px;
    color: #ffffff;
    padding: 0px;
}
.dark #chart .apexcharts-yaxis .apexcharts-yaxis-texts-g text {
    font-size: 12px;
    line-height: 16px;
    color: #fff;
    fill: #fff;
}
.dark #chart .apexcharts-legend.apexcharts-align-center.apx-legend-position-bottom .apexcharts-legend-series .apexcharts-legend-text {
    color: #fff !important;
    fill: #fff;
}
.dark .MuiBox-root .asset-details-container > .pie-chart-container > div:first-child .subtitle {
    font-size: 12px;
    line-height: 16px;
    color: #fff;
    font-weight: 400;
}
span.apexcharts-legend-text {
    color: #333333;
    font-size: 14px !important;
    font-weight: 700 !important;
}
.apexcharts-legend-series {
    margin: 0px !important;
}

/* privacy policy & disclaimer page styling ------------ below*/

.privacy-box,
.term-box,
.disclaimer-box {
    display: flex !important;
    flex-direction: column !important;
    /* align-items: center; */
    padding-bottom: 250px;
    overflow-x: hidden;
    background-color: #ebebeb3b;
    /* background-color: #072125; */
    /* border: 5px solid green; */
}

.privacy-title-box,
.terms-title-box,
.disclaimer-title-box {
    height: 185px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    box-shadow: 0 1px 1px rgba(182, 182, 182, 0.377);
    margin-bottom: 37px;
    /* border: 2px solid red; */
}

.privacy-main-title,
.terms-main-title,
.disclaimer-main-title {
    font-family: 'Termina';
    font-style: normal;
    font-weight: 900;
    font-size: 56px;
    line-height: 67px;
    color: #333333;
    /* border: 2px solid orange; */
}

.privacy-content,
.terms-content,
.last-updated-text,
.disclaimer-content {
    padding: 0 15%;
    color: #595959;
    font-size: 14px;
    font-weight: 600;
    /* border: 2px solid sandybrown; */
}

.privacy-rules-text,
.terms-rules-text,
.disclaimer-rules-text {
    font-size: 18px;
    line-height: 32px;
}

.privacy-rules-text-list li,
.terms-rules-text-list li,
.disclaimer-rules-text-list li {
    font-size: 16px;
    list-style: square;
    font-weight: 100;
    line-height: 30px;
}

.privacy-rules-list-number li,
.terms-rules-list-number li,
.disclaimer-rules-list-number li {
    list-style: number;
    list-style-position: inside;
    font-size: 15px;
    font-weight: 100;
    line-height: 30px;
}

.apexcharts-svg {
    overflow: visible;
}

.chart-container {
    padding: 2rem;
    padding-right: 2.7rem;
}

.right-text-list {
    /* color: cadetblue; */
    /* width: 985px; */
    position: relative;
}

.right-text-list li {
    line-height: 24px;
    font-size: 15px !important;
    color: #616161;
}

.privacy-rules-mini-heading,
.terms-rules-mini-heading,
.disclaimer-rules-mini-heading {
    font-size: 22px;
    color: black;
    font-weight: 900;
}

.black-strong-rule {
    color: #000000;
    font-size: 19.5px;
}

.privacy-rule-table {
    border-collapse: separate;
    text-indent: initial;
    border-spacing: 2px;
}

.privacy-rule-table tr {
    display: table-row;
    vertical-align: inherit;
    border-color: inherit;
}

.privacy-rule-table td {
    width: 33.8274%;
    border: 1px solid black;
    font-weight: 100;
    font-size: 16px;
    text-align: center;
    line-height: 28px;
}

/* header box */

.header-options {
    display: flex !important;
    align-items: center !important;
    width: 200px !important;
    justify-content: flex-end !important;
}

/* share-box */

.share-box {
    padding-bottom: 10px;
    outline: none;
}

.modal-header {
    /* border: 1px solid gold; */
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 2% 4%;
}

.modal-close-btn {
    cursor: pointer;
}

.content {
    /* border: 2px solid blue; */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.social-btns-box {
    /* border: 1px solid red; */
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    padding: 32px 0px;
}

.social-icon-tag {
    padding-top: 10%;
    font-size: 16px;
}

.social-btns {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
}

.text-field-copy-btn {
    /* border: 1px solid black; */
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    /* background-color: #fafafa; */
    height: 40px;
    padding: 2% 4%;
}

.copy-field {
    border: none;
}

.copy-link {
    font-size: 18px;
    cursor: pointer;
    color: #65a9ff;
}

.css-12qic5q-MuiInputBase-root-MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
    /* border: none; */
}

.css-1fax5zq-MuiInputBase-input-MuiOutlinedInput-input {
    width: 275px;
    background-color: transparent;
}
.css-8a617k-MuiInputBase-input-MuiOutlinedInput-input {
    width: 275px;
    background-color: transparent;
}

.text-field-copy-btn {
    /* background-color: #fafafa; */
}
/* background-color: #fafafa;
    height: 40px;
    padding: 2%;
    background: #1a223f; */

/* privacy policy & disclaimer page styling ------------ above*/

.dark .apexcharts-inner.apexcharts-graphical .apexcharts-xaxis .apexcharts-xaxis-texts-g text tspan {
    fill: #ffffff;
}

.light .apexcharts-inner.apexcharts-graphical .apexcharts-xaxis .apexcharts-xaxis-texts-g text tspan {
    fill: #000000;
}

.MuiBox-root .asset-details-container > .pie-chart-container .slick-slider .slick-arrow {
    display: none !important;
}
.MuiBox-root .asset-details-container > .pie-chart-container .slick-slider .slick-dots {
    margin-top: 20px;
    bottom: -40px;
}
.dark .MuiBox-root .asset-details-container > .pie-chart-container .slick-slider .slick-dots li button:before {
    color: #fff;
}
.light .MuiBox-root .asset-details-container > .pie-chart-container .slick-dots li.slick-active button:before {
    opacity: 0.75;
    color: #000;
}

.apexcharts-legend-series span {
    margin-right: 20px;
    margin-top: 10px;
}

.dark #chart text {
    fill: #fff;
}

.collection-image img {
    max-width: unset;
}

div.loading-page-data {
    opacity: 0.5;
}

.table-loader {
    position: fixed;
    top: 45%;
    left: 50%;
}

.asset-details-container .slick-list {
    padding: 0 5px;
}

.form-error {
    color: #f78181;
    padding-left: 10px;
}

.form-success {
    color: #52efc4;
    padding-left: 10px;
}

.btn.btn-white {
    background: #fff;
    color: #333333;
}

.footer-box {
    display: flex !important;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    /* border: 1px solid white; */
}

.footer-copyright-menu-links-box {
    /* border: 1px solid red; */
    display: flex;
    flex-direction: row;
    align-items: center;
}

/* .footer-copyright-year{
    border: 2px solid red;
} */

.footer-social-media {
    /* border: 2px solid gold; */
    display: flex;
    flex-direction: row;
}

.switch-theme-toggle-container,
.switch-theme-toggle-container:hover {
    background-color: transparent;
    box-shadow: none;
}

.switch-theme-toggle {
    cursor: pointer;
}

.topbar .ticker-container td {
    padding: unset;
}

.switch-theme-toggle .switch-theme-label {
    margin-top: -5px;
}

.subscribe-email-container div,
.subscribe-email-container input {
    background: #ffffff !important;
}

.light .switch-theme-btn svg {
    background: #30455a;
    fill: #82a2c1;
    width: 40px;
    height: 40px;
    border-radius: 20px;
    padding: 8px;
}

.dark .switch-theme-btn svg {
    background: #82a2c1;
    fill: #ffffff;
    width: 40px;
    height: 40px;
    border-radius: 20px;
    padding: 8px;
    stroke: white;
}
.social-btns svg {
    padding: 5px;
    width: 50px;
    height: 50px;
    fill: white;
    border-radius: 7px;
}
.social-btns.email-icon svg {
    fill: none;
}

.facebook-icon svg {
    background: #38569e;
}
.twitter-icon svg {
    background: #17a2f2;
}
.text-field-copy-btn input {
    width: 280px;
}
.share-popUp {
    display: flex;
    vertical-align: middle;
    cursor: pointer;
}

.share-popUp span {
    margin: 4px 5px;
    font-size: 18px;
}

.nft-share-search-box {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.share-new-btn,
.asset-new-btn,
.asset-details-new-btn,
.platform-share-new-btn,
.platform-details-share-new-btn,
.asset-pool-share-new-btn,
.lending-pool-share-new-btn {
    padding-top: 2.5px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 95px;
    height: 30px;
    background-color: #177fff;
    border-radius: 30px;
    box-shadow: 0 2px 4px rgba(29, 29, 29, 0.788);
}

.icon-tabler-share {
    padding: 2px;
    margin-top: 1px;
}

.dark .share-new-btn,
.dark .asset-new-btn,
.dark .asset-details-new-btn,
.dark .platform-share-new-btn,
.dark .platform-details-share-new-btn,
.dark .asset-pool-share-new-btn,
.dark .lending-pool-share-new-btn {
    box-shadow: 0 2px 4px rgba(29, 29, 29, 0.322);
}

.share-new-btn,
.asset-new-btn,
.asset-details-new-btn,
.platform-share-new-btn,
.platform-details-share-new-btn {
    margin-right: 25px;
}

.asset-share-search-box {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.asset-details-share-search-box {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.platform-share-search-box {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.platform-details-share-search-box {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.asset-pool-share-box,
.lending-pool-share-box {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    padding-right: 30px !important;
}

.icon-tabler-share,
.share-popUp span {
    color: rgb(255, 255, 255);
    font-size: 16px;
}

@media screen and (max-width: 1149px) {
    .footer-social-img {
        width: 28px;
    }
}

@media screen and (max-width: 1160px) {
    .footer-copyright-year p {
        font-size: 13px !important;
    }

    .footer-social-img {
        width: 24px;
    }
    .footer-social-media {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .footer-menu-link-a {
        font-size: 13px;
    }
}

@media screen and (max-width: 1123px) {
    .footer-box {
        flex-direction: column;
    }
    .footer-copyright-year p {
        font-size: 14px !important;
    }
    .footer-menu-link-a {
        font-size: 14px;
    }
    .footer-social-img {
        width: 30px;
    }
}

@media screen and (max-width: 1103px) {
    .header-list-item {
        /* border: 2px solid gold; */
        display: flex;
        flex-direction: row;
    }
}

@media screen and (max-width: 712px) {
    .footer-copyright-year p {
        font-size: 13px !important;
    }
    .footer-menu-link-a {
        font-size: 13px;
    }
    .footer-social-img {
        width: 28px;
    }
}

@media screen and (max-width: 1500px) {
    /* privacy & dislcaimer page styling */
    .privacy-content,
    .terms-content,
    .last-updated-text,
    .disclaimer-content {
        padding: 0 15%;
    }
}

@media screen and (max-width: 1300px) {
    #root .MuiContainer-root {
        max-width: 1170px;
    }
    #updates ul li {
        width: 180px;
    }
    #banner .right-block {
        width: 35%;
    }
    #banner .left-block {
        width: 60%;
    }
    #community,
    #analysis {
        background-size: 50%;
    }
    /* privacy & dislcaimer page styling */
    .privacy-content,
    .terms-content,
    .last-updated-text,
    .disclaimer-content {
        padding: 0 12%;
    }
}

@media screen and (max-width: 1199px) {
    #root .datatab table tbody tr th a,
    #root .datatab table tbody tr td a {
        font-size: 14px;
        line-height: 20px;
    }

    /* privacy & dislcaimer page styling */
    .privacy-content,
    .terms-content,
    .last-updated-text,
    .disclaimer-content {
        padding: 0 12%;
    }

    #root .MuiContainer-root {
        max-width: 980px;
    }
    h1 {
        font-size: 56px;
        line-height: 68px;
    }
    #banner {
        padding: 107px 0px 122px;
    }
    .nav {
        max-width: 500px;
    }
    header .nav a {
        font-size: 16px;
        margin: 0px;
        padding: 0px;
    }
    #about > .MuiContainer-root > div.MuiGrid-root > div {
        max-width: 300px;
    }
    #updates .left-block {
        margin-left: 0;
    }
    #about {
        padding: 90px 0px 80px;
    }
    #chat .right-block {
        padding-left: 30px;
    }
    #updates ul {
        column-count: 2;
        padding-right: 20px;
    }
    #updates ul li {
        width: 213px;
    }
    #community h2 {
        font-size: 36px;
        line-height: 46px;
    }
    #updates ul li {
        margin: 0px 18px 25px 0px;
    }
    #updates ul li:nth-child(3) {
        margin-top: 0;
    }
    #updates ul li:nth-child(4) {
        margin-top: 20px;
    }
    #team .teamlist > div {
        padding: 0px 15px;
    }
    #team .teamlist > div span,
    #team .teamlist > div p {
        font-size: 16px;
    }
    #analysis ul li a {
        padding: 20px;
    }
    #analysis ul li a p {
        margin: 0px;
    }
    #updates > .flex-container > div {
        justify-content: space-between;
        align-items: center;
    }
    /* App Css */
    .toplist li {
        margin-right: 10px;
    }
    #root .appheader .MuiContainer-root {
        max-width: 100%;
        padding-left: 30px;
        padding-right: 30px;
    }
    .MuiBox-root.css-8atqhb > .MuiPaper-root > .MuiBox-root.css-0 input[type='text'] {
        width: 285px;
    }
    .MuiBox-root.css-8atqhb > .MuiPaper-root > .MuiBox-root.css-0 {
        padding: 0px 0px 0px 30px;
    }
    .datatab table thead tr th:first-child,
    .datatab table tbody tr th:first-child {
        padding-left: 30px;
    }
    #root .dark .platform-tvl-info {
        background: #82a1c1;
        padding: 21px 30px 16px;
    }

    .asset-pool-share-box,
    .lending-pool-share-box {
        padding-right: 30px !important;
    }
}
#root .nav .title,
.menubar .menu__btn {
    display: none;
    background: none;
    margin: 0px;
    padding: 0px;
    background: none;
}

@media screen and (max-width: 991px) {
    #root .datatab table tbody tr th a,
    #root .datatab table tbody tr td a {
        font-size: 12px;
        line-height: 20px;
    }

    #root .datatab table tbody tr td {
        font-size: 14px;
    }

    body,
    p {
        font-size: 18px;
        line-height: 30px;
    }
    #root .MuiContainer-root {
        max-width: 750px;
    }
    h1 {
        font-size: 46px;
        line-height: 58px;
    }
    #banner .left-block {
        padding-right: 40px;
    }
    #banner .right-block {
        margin-top: 60px;
    }
    #banner .left-block ul li:last-child {
        margin-right: 0px;
    }
    #about > .MuiContainer-root > div.MuiGrid-root > div {
        max-width: 230px;
    }
    #about > .MuiContainer-root > div.MuiGrid-root > div h4 {
        font-size: 17px;
        line-height: 24px;
        color: rgba(51, 51, 51, 0.9);
    }
    #about > .MuiContainer-root > div.MuiGrid-root > div p {
        font-size: 16px;
        line-height: 24px;
        margin-top: 33px;
    }
    #chat .right-block h2 {
        font-size: 56px;
        max-width: 400px;
        line-height: 70px;
        margin-bottom: 30px;
    }
    #analysis ul {
        max-width: 90%;
    }
    #analysis ul li a p {
        margin: 0px;
        font-size: 16px;
        line-height: 26px;
    }
    #community h2 {
        font-size: 29px;
        line-height: 46px;
    }
    #community .left-block p {
        font-weight: 400;
        font-size: 20px;
        line-height: 30px;
        margin-bottom: 54px;
    }
    #chat {
        padding: 90px 0px 91px;
    }
    #community {
        background-size: 65%;
    }
    #updates #root .MuiContainer-root {
        flex-direction: column;
    }
    #updates > .flex-container > div {
        justify-content: space-between;
        align-items: center;
        flex-direction: column;
    }
    #updates .left-block {
        width: 100%;
        flex-basis: 100%;
        flex-grow: 0;
        max-width: 100%;
    }
    #updates ul {
        column-count: 3;
        padding-right: 0;
        max-width: 640px;
        margin: 0px auto 40px;
        display: inherit;
    }
    #updates ul li {
        margin: 0px 18px 16px 0px;
        width: 202px;
    }
    #updates ul li:nth-child(3) {
        margin-top: 45px;
    }
    #updates ul li:nth-child(4) {
        margin-top: 0;
    }
    #updates .right-block {
        width: 100%;
        margin: 31px auto 0;
    }
    #team .teamlist > div h4 {
        font-weight: 900;
        font-size: 17px;
        line-height: 28px;
        color: #072125;
    }
    .ftr-right {
        max-width: 50%;
        width: 50%;
        display: flex;
        padding-left: 0;
    }
    #toggle {
        display: flex;
        align-items: center;
        position: absolute;
        top: 9px;
        right: 0px;
        width: 40px;
        height: 40px;
        cursor: pointer;
        z-index: 10;
        background: #ffffff;
        border-radius: 1000px;
        padding: 14px 11px;
    }

    #toggle span:after,
    #toggle span:before {
        content: '';
        position: absolute;
        left: 0;
        top: -4px;
    }
    #toggle span:after {
        top: 4px;
    }
    #toggle span {
        position: relative;
        display: block;
    }

    #toggle span,
    #toggle span:after,
    #toggle span:before {
        width: 100%;
        height: 2px;
        background-color: #000;
        transition: all 0.3s;
        backface-visibility: hidden;
        border-radius: 2px;
    }

    /* on activation */
    #toggle.show {
        background: no-repeat;
    }
    #toggle:focus span,
    #toggle.show span {
        background-color: transparent;
        transform: rotate(45deg);
    }
    #toggle:focus span:before,
    #toggle.show span:before {
        top: 0;
        transform: rotate(0);
        background-color: #fff;
        width: 21px;
    }
    #toggle:focus span:after,
    #toggle.show span:after {
        top: 0;
        transform: rotate(90deg);
        background-color: #fff;
        width: 21px;
    }

    .nav.show {
        visibility: visible;
        right: 0;
    }
    #root .nav .title,
    .menubar .menu__btn {
        display: block;
    }

    header .nav {
        display: block;
        position: fixed;
        visibility: hidden;
        top: 0;
        right: -100%;
        width: 280px;
        height: 100%;
        margin: 0;
        padding: 99px 0 80px 32px;
        list-style: none;
        background-color: #30455a;
        box-shadow: 1px 0px 6px rgba(0, 0, 0, 0.2);
        transition-duration: 0.25s;
        z-index: 9;
    }
    header .nav a {
        transition-duration: 0.25s;
    }
    header .flex-container {
        display: inline-block;
    }
    .logo {
        float: left;
    }
    .launch-btn {
        max-width: 152px;
        float: right;
        margin-right: 50px;
        margin-top: 6px;
    }
    .nav {
        float: left;
        width: 100%;
        margin-bottom: 45px;
    }
    .nav .title {
        display: block;
        position: absolute;
        top: 33px;
        left: 32px;
        border: 0px;
        width: auto;
        font-weight: 900 !important;
        font-size: 24px !important;
        line-height: 54px !important;
        color: #fff;
        padding: 0px !important;
    }
    header .nav a {
        width: 100%;
        padding: 0px;
        float: left;
        color: #fff;
        font-weight: 400;
        font-size: 24px;
        line-height: 33px;
        margin: 0px 0px 30px 0px;
        text-align: left;
        align-items: flex-start;
        justify-content: flex-start;
    }
    .nav a.active {
        color: #2980ff;
    }
    #team .teamlist > div h4 {
        font-weight: 900;
        font-size: 16px;
        line-height: 29px;
        color: #072125;
    }
    #analysis ul.slider.animated {
        width: 100%;
        max-width: 100%;
    }
    #analysis ul li a {
        padding: 15px 15px;
    }
    #analysis {
        padding: 75px 0px 50px;
    }
    #community {
        padding: 108px 0px 334px;
    }
    .footer-top .ftr-right {
        padding-left: 70px;
    }
    #team .teamlist > div {
        padding: 0px 15px;
        min-height: 300px;
    }
    .ftr-left {
        padding-left: 0;
    }
    #copywrite p {
        padding-left: 0;
    }
    .appheader header > div > div .MuiTypography-body1 {
        max-width: 180px;
        margin-top: 7px;
    }
    .appheader header > div > div .app-header-menu a {
        font-size: 16px;
        margin-left: 10px;
    }
    .appheader header > div > div .app-header-menu {
        margin-top: 0;
    }
    .appheader header > div > div > a {
        float: right;
        margin-right: 0;
        margin-top: 0;
    }
    .css-1tndf5z-MuiInputBase-root-MuiInput-root {
        display: inline-block;
    }
    .topbar ul {
        padding: 0px 20px;
    }
    .appheader header > div > div .app-header-menu {
        float: left;
        margin-left: 20px;
        margin-top: 12px;
    }
    .light .MuiBox-root .asset-details-container,
    .dark .MuiBox-root .asset-details-container {
        flex-direction: column;
    }
    .light .MuiBox-root .asset-details-container > .pie-chart-container,
    .dark .MuiBox-root .asset-details-container > .pie-chart-container,
    .light .MuiBox-root .asset-details-container > .asset-details,
    .dark .MuiBox-root .asset-details-container > .asset-details {
        width: 100%;
        max-width: 100%;
        margin: 13px 0px;
    }

    /* privacy & dislcaimer page styling */
    .privacy-content,
    .terms-content,
    .last-updated-text,
    .discalimer-content {
        padding: 0 8%;
    }

    .privacy-main-title,
    .terms-main-title,
    .disclaimer-main-title {
        font-size: 46px;
    }

    /* pop */
    .header-options {
        width: 170px !important;
    }
}
input#menu__toggle,
.menu__btn svg {
    display: none !important;
}

@media only screen and (min-width: 992px) {
    input#menu__toggle,
    .menu__btn svg,
    #root .nav .title,
    .menubar .menu__btn {
        display: none !important;
    }
}

@media only screen and (max-width: 800px) {
    .header-options {
        width: 140px !important;
    }
}

@media only screen and (max-width: 767px) {
    .share-new-btn,
    .asset-new-btn,
    .asset-details-new-btn,
    .platform-share-new-btn,
    .platform-details-share-new-btn {
        margin-right: 0px !important;
    }

    .MuiBox-root.css-8atqhb > .MuiPaper-root > .MuiBox-root.css-0 {
        padding: 0 30px 0 30px;
    }
    .collection-image img {
        width: 50px;
    }

    #root .datatab table tbody tr th a,
    #root .datatab table tbody tr td a {
        font-size: 12px;
        line-height: 20px;
    }

    #root .datatab table tbody tr td {
        font-size: 14px;
    }

    #root .datatab table tbody tr th a,
    #root .datatab table tbody tr td a {
        font-size: 12px;
        line-height: 20px;
    }

    #root .datatab table tbody tr td {
        font-size: 14px;
    }

    #banner .left-block h1 span {
        font-size: 20px;
        line-height: 46px;
        color: #2980ff;
        font-weight: 400;
        display: inline-block;
        width: 100%;
    }
    #root .datatab table tbody tr td .tvl-chart {
        width: 190px;
        max-width: 190px;
        display: none;
    }
    #root .datatab table tbody tr td:last-child {
        font-size: 12px;
        line-height: 16px;
    }
    #root .datatab table tbody tr td .reward-value.ohm {
        background: none;
        padding-left: 0px;
    }
    .search-asset-input > div {
        display: none;
    }
    #root .dark .datatab table tbody tr td .blockchain-value {
        color: rgba(255, 255, 255, 0.6);
        font-size: 9px;
    }
    .logo {
        max-width: 30%;
    }
    .launch-btn {
        font-size: 12px;
        line-height: 19px;
        width: 114px;
        padding: 8.5px 0px;
        display: inline-block;
        min-width: inherit;
        text-align: center;
    }
    #banner .right-block {
        display: none;
    }
    #toggle {
        top: 4px;
    }
    #banner .left-block {
        width: 100%;
        max-width: 100%;
        padding-right: 0;
        flex-basis: 100%;
    }
    button {
        font-weight: 700;
        font-size: 14px;
        line-height: 19px;
        padding: 13px 20px;
        min-width: 128px;
    }
    h2 {
        font-size: 24px;
        line-height: 28px;
    }
    body,
    p {
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
    }
    .footer-top .ftr-left p {
        max-width: 100%;
    }
    .footer-top .ftr-right {
        padding-left: 0;
    }

    #about > .MuiContainer-root > div.MuiGrid-root {
        flex-direction: column;
    }
    #about > .MuiContainer-root > div.MuiGrid-root > div {
        max-width: 100%;
    }
    #about > .MuiContainer-root > div.MuiGrid-root > div p {
        font-size: 20px;
        line-height: 32px;
        margin-top: 24px;
    }
    #about p {
        font-size: 16px;
        line-height: 21px;
    }
    #about > .MuiContainer-root > div.MuiGrid-root > div {
        padding: 34px 0px;
        margin-top: 0;
    }
    #about > .MuiContainer-root > div.MuiGrid-root > div:first-child {
        margin-top: 30px;
    }
    #root #analysis .MuiContainer-root .MuiGrid-grid-xs-6 {
        flex-basis: 100%;
        flex-grow: 0;
        max-width: 100%;
    }
    #analysis .right-block .slick-slider {
        display: none;
    }
    #analysis ul {
        flex-direction: column;
        max-width: 100%;
        margin-left: 0;
    }
    #analysis .right-block {
        display: block;
        width: 100%;
        text-align: center;
        position: inherit;
    }
    #analysis {
        background: #dfefff;
        background: linear-gradient(0deg, rgba(255, 255, 255, 1) 0%, rgba(223, 239, 255, 1) 52%, rgba(223, 239, 255, 1) 100%);
        padding: 45px 0px 80px;
    }
    #analysis ul li a h4 {
        font-size: 20px;
        line-height: 36px;
        margin-top: 0px;
        margin-bottom: 0px;
    }
    #analysis ul li {
        margin: 0px 0px 12px 0px;
        display: inline-block;
        width: 100%;
    }
    .flex-container {
        flex-direction: column;
    }
    #chat .right-block,
    #chat .left-block {
        width: 100%;
        flex-basis: 100%;
        max-width: 100%;
    }
    #chat .right-block {
        padding-left: 0;
        margin-top: 30px;
        text-align: left;
    }
    .footer-top .ftr-right,
    .footer-top .ftr-left {
        max-width: 100%;
        width: 100%;
        margin-bottom: 30px;
        flex-direction: column;
    }
    #team .teamlist > div span.desg,
    #team .teamlist > div h4 {
        display: none;
    }

    .footer-top .ftr-right .resources {
        margin-top: 40px;
    }

    #team .teamlist > div span.tw-icon img {
        margin: 0px 0px;
        width: 16px;
    }
    #team .teamlist > div {
        display: inline-block;
        width: 100%;
        position: relative;
        width: 25%;
    }
    #team .teamlist > div #team .teamlist > div > span {
        display: inline-block;
        width: 100%;
    }
    #team .teamlist > div span.tw-icon {
        margin-top: 0;
    }
    .launch-btn {
        margin-top: 0px;
    }
    .menu__btn {
        top: 0px;
    }
    #banner .left-block ul li button {
        float: left;
        line-height: 19px;
        padding: 10px 21px;
        font-size: 14px;
    }
    #banner .btn {
        display: inline-block;
        line-height: 45px;
    }
    #banner .btn img {
        margin-left: 10px;
        margin-top: 1px;
        float: right;
        max-width: 14px;
    }

    .logo {
        margin-top: 3px;
        width: 158px;
        max-width: 158px;
    }
    #banner {
        padding: 0px 0px 50px;
    }
    #about {
        padding: 60px 0px 40px;
    }
    #analysis .left-block p,
    #analysis .left-block h2 {
        text-align: center;
    }
    #analysis .left-block p {
        font-size: 16px;
        line-height: 22px;
        max-width: 100%;
    }
    #analysis .left-block ul li a p {
        text-align: left;
        font-size: 12px;
        line-height: 22px;
    }
    #analysis .right-block img {
        display: none;
    }
    #analysis .right-block .mobile-image {
        display: none;
    }
    #analysis ul li a {
        padding: 20px;
        max-width: 100% !important;
    }
    #analysis .left-block ul li a p {
        font-size: 14px;
        line-height: 22px;
        max-width: 100%;
    }
    #chat {
        padding: 0px 0px 91px;
    }
    #chat .flex-container {
        align-items: baseline;
    }
    #chat .right-block h2 {
        font-size: 36px;
        line-height: 46px;
        margin: 0px auto 30px;
    }
    #community .left-block {
        max-width: 620px;
        margin: 0px auto;
        padding: 0px 15px;
        flex-basis: 100%;
    }
    #community .flex-container {
        padding: 0px;
        max-width: 100%;
    }
    #community {
        padding-top: 52px;
        background-size: 100%;
        padding-bottom: 314px;
    }
    #updates .left-block {
        display: none;
    }
    #updates {
        padding: 51px 0px 51px;
    }
    #updates .right-block {
        margin-top: 0;
        padding: 35px 27px 32px;
        text-align: center;
    }
    #updates form .form-row {
        margin: 12px 0px 40px 0px;
        padding: 0;
        background: none;
        border-radius: 0;
    }
    #updates form div.MuiBox-root .MuiFormControl-root .MuiOutlinedInput-root input[type='text'] {
        width: 100%;
        line-height: 48px;
        text-align: center;
        border-radius: 50px;
        font-size: 16px;
    }
    #updates form button {
        position: inherit;
        bottom: 0;
        right: 0;
        margin-top: 12px;
    }
    #team h2 {
        font-weight: 600;
        font-size: 22px;
        line-height: 36px;
        text-align: left;
    }
    #team p {
        text-align: left;
        font-size: 16px;
    }
    #team {
        background: #ebf2fe;
        padding-top: 72px;
    }
    #team ul {
        margin-top: 20px;
    }
    #team .teamlist > div {
        padding: 0px 8px;
        margin-bottom: 12px;
        min-height: inherit;
    }
    #wisdom {
        text-align: left;
    }
    #wisdom h2 {
        font-size: 20px;
        line-height: 24px;
        max-width: 300px;
    }
    #wisdom .default-btn {
        font-weight: 700;
        font-size: 14px;
        line-height: 20px;
    }
    #wisdom p {
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        margin-bottom: 45px;
    }
    #copywrite {
        background: #37424e;
        margin-top: 40px;
    }
    footer {
        padding: 50px 0px 0px;
    }
    .ftr-right {
        max-width: 200px;
        margin-top: 40px;
    }
    #community h2 {
        font-size: 24px;
        line-height: 35px;
        font-weight: 400;
    }
    #community .left-block p {
        font-size: 16px;
        line-height: 28px;
        margin-bottom: 54px;
    }
    #chat .left-block {
        padding: 0px 0%;
        text-align: left;
    }
    #chat .left-block img {
        max-width: 203px;
    }
    #community .mobile-block {
        display: block;
        width: 100%;
        margin-top: 30px;
    }
    #community .mobile-block img {
        float: right;
    }
    #community .mobile-block {
        display: none;
    }
    #analysis .left-block {
        width: 100%;
        display: inline-block;
    }
    #banner .left-block p {
        font-size: 14px;
        line-height: 20px;
        max-width: 270px;
    }
    #updates .right-block h2 {
        font-weight: 600;
        font-size: 24px;
        line-height: 32px;
    }
    #updates .right-block p {
        font-size: 18px;
        line-height: 24px;
    }
    #updates form label {
        margin-left: 0px;
    }
    #updates form .form-row button.default-btn.blue {
        line-height: 40px !important;
    }
    #copywrite p {
        text-align: center;
    }
    .ftr-right,
    .ftr-left {
        max-width: 100%;
        width: 100%;
    }
    #wisdom .MuiContainer-root button,
    #root button,
    .default-btn {
        font-weight: 700;
        font-size: 14px;
        line-height: 19px;
        padding: 0px 20px;
        line-height: 40px;
        min-width: inherit;
    }

    /* App Css */
    #root .appdash header .app-header-menu {
        margin-top: 0;
        float: left;
        width: 100%;
        margin: 10px 0px 0px;
        display: block;
    }
    #root .appheader .MuiContainer-root {
        padding-left: 20px;
        padding-right: 20px;
    }
    #root .appheader header {
        padding: 20px 0px 15px;
    }
    .appheader header > div > div .app-header-menu a {
        font-size: 14px;
        margin-left: 10px;
        padding: 0px;
        line-height: 19px;
    }
    .appheader header > div > div .app-header-menu a:first-child {
        margin-left: 0px;
    }
    #root main {
        margin: 111px 0px 0px;
    }
    .css-1tndf5z-MuiInputBase-root-MuiInput-root {
        display: none;
    }
    .datatab table thead tr th {
        font-size: 12px !important;
        line-height: 16px;
        font-weight: 700 !important;
    }
    .datatab table thead tr th span .secondary-column-name {
        font-size: 9px;
        line-height: 12px;
        color: rgba(255, 255, 255, 0.6);
    }
    #root .datatab table tbody tr td .reward-value {
        font-size: 12px;
        line-height: 14px;
    }
    #root .datatab table tbody tr td .price-value {
        font-size: 9px;
        line-height: 12px;
    }
    #root .datatab table tbody tr td {
        font-size: 12px;
        line-height: 16px;
    }
    #root .datatab table tbody tr td:nth-child(4) {
        width: 110px;
        max-width: 110px;
    }
    .toplist li {
        margin-right: 10px;
        font-size: 12px;
        line-height: 16px;
    }
    #root .appdash main {
        margin-top: 0px;
    }
    #root .appdash header {
        max-height: 118px;
        overflow: hidden;
    }
    #root .appdash header:first-child {
        overflow: hidden;
    }
    .topbar ul li:nth-child(4),
    .topbar ul li:nth-child(3) {
        display: none;
    }
    .topbar ul li {
        padding: 0px 15px;
    }
    .MuiPagination-ul {
        justify-content: center;
    }
    #root .light .platform-tvl-info,
    #root .dark .platform-tvl-info {
        display: none;
    }

    /*disclaimer mini content title style*/

    .terms-content-title,
    .disclaimer-content-title {
        font-size: 24px;
    }

    .privacy-main-title,
    .terms-main-title,
    .disclaimer-main-title {
        font-size: 40px;
    }

    .switch-theme-toggle-container {
        top: 60px;
        right: 20px;
    }
}

@media only screen and (max-width: 675px) {
    .privacy-main-title,
    .terms-main-title,
    .disclaimer-main-title {
        font-size: 33px;
    }

    .footer-copyright-year p {
        font-size: 12px !important;
    }
    .footer-menu-link-a {
        font-size: 12px;
    }
    .footer-social-img {
        width: 22px;
    }

    .footer-box {
        justify-content: space-evenly;
    }
}

@media only screen and (max-width: 660px) {
    .datatab table thead tr th:first-child,
    .datatab table tbody tr th:first-child,
    .datatab table tbody tr td:first-child {
        padding-left: 40px !important;
    }
}

@media only screen and (max-width: 640px) {
    .app-copyright .MuiContainer-root p {
        margin: 0;
    }

    .footer-copyright-menu-links-box {
        flex-direction: column;
        align-items: center;
    }

    .footer-copyright-year p {
        font-size: 14px;
    }
    .footer-menu-link-a {
        font-size: 14px;
    }
    .footer-social-img {
        width: 26px;
    }
}

@media only screen and (max-width: 605px) {
    #root .MuiPagination-ul li button {
        padding: 5px 11px;
        margin: 0px;
    }
    #root .MuiPagination-ul li {
        margin: 0px 3px;
    }

    /* disclaimer privacy padding */
    .privacy-content,
    .terms-content,
    .last-updated-text,
    .disclaimer-content {
        padding: 0 7%;
    }

    .privacy-main-title,
    .terms-main-title,
    .disclaimer-main-title {
        font-size: 30px;
    }
    .privacy-rules-mini-heading,
    .terms-rules-mini-heading,
    .disclaimer-rules-mini-heading {
        line-height: 30px;
    }

    .pie-chart-container {
        /* border: 2px solid black; */
    }

    .header-options {
        width: 140px !important;
    }

    .switch-theme-btn svg {
        margin-left: 5px;
    }

    .social-btns svg {
        width: 40px;
        height: 40px;
    }
}

@media only screen and (max-width: 568px) {
    .footer-social-media {
        /* border: 2px solid red; */
        flex-direction: column;
    }

    .footer-btn-div {
        /* border: 1px solid gold; */
    }

    .header-options {
        width: 160px !important;
    }
}

@media only screen and (max-width: 524px) {
    .header-options {
        width: 140px !important;
    }

    .appdash .switch-theme-btn svg {
        width: 30px;
        height: 30px;
        border-radius: 15px;
        padding: 6px;
    }

    .share-popUp span {
        font-size: 16px;
    }
    .icon-tabler-share {
        padding-top: 2px;
    }

    .share-new-btn,
    .asset-new-btn,
    .asset-details-new-btn,
    .platform-share-new-btn,
    .platform-details-share-new-btn,
    .asset-pool-share-new-btn,
    .lending-pool-share-new-btn {
        width: 100px;
        height: 34px;
    }
}

@media only screen and (max-width: 500px) {
    .privacy-main-title,
    .terms-main-title,
    .disclaimer-main-title {
        font-size: 26px;
    }
    .last-updated-text {
        font-size: 12px;
    }
    .privacy-rules-text,
    .terms-rules-text,
    .disclaimer-rules-text {
        font-size: 15px;
    }
    .privacy-rules-text-list li,
    .terms-rules-text-list li,
    .disclaimer-rules-text-list li {
        font-size: 15px;
    }
    .privacy-rules-list-number li,
    .terms-rules-list-number li,
    .disclaimer-rules-list-number li {
        font-size: 14px;
    }
    .privacy-rules-mini-heading,
    .terms-rules-mini-heading,
    .disclaimer-rules-mini-heading {
        font-size: 18px;
        line-height: 28px;
    }
}

@media only screen and (max-width: 479px) {
    .footer-copyright-year p {
        font-size: 10px;
    }
    .footer-menu-link-a {
        font-size: 12px;
    }
    .footer-social-img {
        width: 22px;
    }
}

@media only screen and (max-width: 450px) {
    .privacy-title-box,
    .terms-title-box,
    .disclaimer-title-box {
        height: 120px;
    }

    .privacy-main-title,
    .terms-main-title,
    .disclaimer-main-title {
        font-size: 45px;
    }

    .privacy-content,
    .terms-content,
    .disclaimer-content,
    .last-updated-text {
        padding: 0 5%;
        /* padding: 0 15px; */
    }

    .privacy-box,
    .terms-box,
    .disclaimer-box {
        padding-bottom: 150px;
    }

    .privacy-main-title,
    .terms-main-title,
    .disclaimer-main-title {
        font-size: 20px;
    }
    .privacy-rule-table td {
        width: 33%;
        border: 1px solid black;
        font-weight: 100;
        font-size: 14px;
        text-align: center;
        line-height: 28px;
    }
    .privacy-rules-mini-heading,
    .terms-rules-mini-heading,
    .disclaimer-rules-mini-heading {
        font-size: 16px;
    }

    .header-options {
        position: relative;
        top: -12px;
    }

    .header-options {
        width: 100px !important;
        position: absolute;
        right: 0px;
        top: 33%;
        transform: translateY(-50%);
    }
    #root .appdash header {
        max-height: none !important;
    }

    .appheader header .MuiToolbar-gutters {
        flex-direction: column;
        position: relative;
    }

    .css-qd8a2v,
    .css-1pwyg {
        max-width: 360px;
    }

    .copy-link {
        font-size: 16px;
    }

    .css-8a617k-MuiInputBase-input-MuiOutlinedInput-input,
    .css-1fax5zq-MuiInputBase-input-MuiOutlinedInput-input {
        width: 240px;
    }

    .share-new-btn,
    .asset-new-btn,
    .asset-details-new-btn,
    .platform-share-new-btn,
    .platform-details-share-new-btn,
    .asset-pool-share-new-btn,
    .lending-pool-share-new-btn {
        width: 90px;
        height: 32px;
    }

    .share-popUp span {
        font-size: 14px;
    }

    .icon-tabler-share {
        padding: 3px;
    }
}

@media only screen and (max-width: 434px) {
    .footer-copyright-year p {
        font-size: 9px;
    }
    .footer-menu-link-a {
        font-size: 10px;
    }
    .footer-social-img {
        width: 22px;
    }
    .appheader header > div > div .app-header-menu a {
        font-size: 16px !important;
    }

    .css-qd8a2v,
    .css-1pwyg {
        max-width: 360px;
    }

    .copy-link {
        font-size: 16px;
    }

    .css-8a617k-MuiInputBase-input-MuiOutlinedInput-input,
    .css-1fax5zq-MuiInputBase-input-MuiOutlinedInput-input {
        width: 240px;
    }
}

@media only screen and (max-width: 426px) {
    .appheader header > div > div .app-header-menu a {
        font-size: 16px !important;
    }
}

@media only screen and (max-width: 422px) {
    .appheader header > div > div .app-header-menu a {
        font-size: 15px !important;
    }
}

@media only screen and (max-width: 420px) {
    #root .btn.btn-grey {
        font-size: 14px;
    }

    #banner .btn {
        font-size: 14px;
    }

    .footer-copyright-year p {
        font-size: 10px;
    }
    .footer-menu-link-a {
        font-size: 10px !important;
    }
    .footer-social-img {
        width: 20px;
    }

    .share-popUp span {
        font-size: 14px;
    }
    .icon-tabler-share {
        padding-top: 3px;
    }

    .share-new-btn,
    .asset-new-btn,
    .asset-details-new-btn,
    .platform-share-new-btn,
    .platform-details-share-new-btn,
    .lending-pool-share-new-btn {
        width: 88px;
        height: 30px;
    }

    .text-field-copy-btn input {
        width: 235px;
    }
}

@media only screen and (max-width: 400px) {
    .privacy-box a,
    .terms-box a,
    .disclaimer-box a {
        font-size: 14px;
    }
    .privacy-rules-text,
    .terms-rules-text,
    .disclaimer-rules-text {
        font-size: 14px;
    }
}

@media only screen and (max-width: 390px) {
    .footer-copyright-year p {
        font-size: 9px;
    }
    .footer-menu-link-a {
        font-size: 9px !important;
    }
    .footer-social-img {
        width: 22px;
    }

    .css-qd8a2v,
    .css-1pwyg {
        max-width: 340px;
    }

    .social-icon-tag {
        font-size: 14px;
    }

    .copy-link {
        font-size: 16px;
    }

    .css-8a617k-MuiInputBase-input-MuiOutlinedInput-input,
    .css-1fax5zq-MuiInputBase-input-MuiOutlinedInput-input {
        width: 240px;
    }
}

@media only screen and (max-width: 378px) {
    .footer-menu-link-a {
        font-size: 9px;
    }

    .footer-social-img {
        width: 19px;
    }

    .footer-menu-links {
        text-align: center;
    }
}

@media only screen and (max-width: 375px) {
    #root .MuiPagination-ul li button {
        padding: 5px 7px;
        margin: 0px;
    }
    .privacy-box a,
    .terms-box a,
    .disclaimer-box a {
        font-size: 12px;
    }
    .privacy-rules-text,
    .terms-rules-text,
    .disclaimer-rules-text {
        font-size: 13px;
    }
    .privacy-rules-mini-heading,
    .terms-rules-mini-heading,
    .disclaimer-rules-mini-heading {
        line-height: 26px;
    }
    #banner .btn img {
        width: 12px;
    }

    .footer-btn-div {
        display: flex;
        flex-direction: row;
    }
}

@media only screen and (max-width: 370px) {
    .launch-btn {
        font-size: 12px;
        line-height: 15px;
        min-width: inherit;
        padding: 9px 15px;
        width: 96px;
    }
    .logo {
        margin-top: 3px;
        width: 120px;
        max-width: 120px;
    }
    #root .appheader .MuiContainer-root {
        padding-left: 15px;
        padding-right: 15px;
    }
    .MuiBox-root.css-8atqhb > .MuiPaper-root > .MuiBox-root.css-0 {
        padding: 0px 15px;
    }

    .asset-pool-share-box,
    .lending-pool-share-box {
        padding-right: 15px !important;
    }
}

@media only screen and (max-width: 364px) {
    .privacy-rule-text a {
        font-size: 11.5px;
    }
    .privacy-main-title,
    .terms-main-title,
    .disclaimer-main-title {
        font-size: 20px;
    }
}

@media only screen and (max-width: 355px) {
    #root .btn.btn-grey {
        font-size: 12px;
    }

    #banner .btn {
        font-size: 12px;
    }

    .css-qd8a2v,
    .css-1pwyg {
        max-width: 320px;
    }

    .social-icon-tag {
        font-size: 12px;
    }

    .copy-link {
        font-size: 16px;
    }

    .css-8a617k-MuiInputBase-input-MuiOutlinedInput-input,
    .css-1fax5zq-MuiInputBase-input-MuiOutlinedInput-input {
        width: 220px;
    }
}

@media only screen and (max-width: 350px) {
    .privacy-main-title,
    .terms-main-title,
    .disclaimer-main-title {
        font-size: 18px;
    }
}

@media only screen and (max-width: 333px) {
    #root .btn.btn-grey {
        font-size: 10px;
    }

    #banner .btn {
        font-size: 10px;
    }

    .light .appheader header > div > div .app-header-menu a {
        font-size: 15px;
    }
    .dark .appheader header > div > div .app-header-menu a {
        font-size: 15px;
    }
}

@media only screen and (max-width: 323px) {
    .footer-copyright-year p {
        font-size: 12px !important;
    }

    .footer-menu-link-a {
        font-size: 9px;
    }

    .footer-social-img {
        width: 19px;
    }

    .footer-menu-links {
        text-align: center;
    }
}
